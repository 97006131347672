import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styled from './data-actions.styled'
import {RefreshButton} from './refresh/refresh-button'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {locationsRefreshedSelector} from '../../../../store/state/locations/selectors'

export function DataActions(): JSX.Element {
    const {width} = useDimensions()
    const locationsRefreshed = useTypedSelector(locationsRefreshedSelector)

    return (
        <Styled.Container width={width}>
            <RefreshButton />
            <Styled.Container width={20}>
                {locationsRefreshed != null ? (locationsRefreshed ? 'Success' : 'Error') : ''}
            </Styled.Container>
        </Styled.Container>
    )
}
