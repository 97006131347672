import {RefreshCcw as RefreshIcon} from 'react-feather'
import {useDispatch} from 'react-redux'
import * as Styled from './refresh-button.styled'
import {refreshLocations} from '../../../../../store/state/locations/action-creators'

export function RefreshButton(): JSX.Element {
    const dispatch = useDispatch()
    return (
        <Styled.Button
            id="refresh-button"
            onClick={() => {
                dispatch(refreshLocations())
            }}>
            <RefreshIcon height={15} width={15} />
            Refresh Locations
        </Styled.Button>
    )
}
