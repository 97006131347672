import {NavBarLink} from './nav-bar-link'

export function NavBarContent(): JSX.Element {
    return (
        <>
            <NavBarLink id="listing" path="/list" navLinkText="Call Home Requests" />
            <NavBarLink id="listing" path="/locations" navLinkText="Locations" />
            <NavBarLink id="swagger" path="/swagger" navLinkText="Swagger" />
        </>
    )
}
