import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {DataActions} from './components/data-actions/data-actions'

export function LocationsPage(): JSX.Element {
    return (
        <FixedPageTemplate>
            <DataActions />
        </FixedPageTemplate>
    )
}
