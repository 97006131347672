import ActionType from './action-type'
import * as Actions from './actions'
import {Location} from './state'
import {ThunkAction} from 'redux-thunk'

import {REST} from '../../../index'
import AppState from '../../types/app-state'
import {Api} from '../../../api/api'

const LOCATIONS_URL = '/api/v1/locations'

const setLocations = (locations: Location[]): Actions.SetLocationsAction => ({
    type: ActionType.SET_LOCATIONS,
    payload: locations,
})

const requestLocations = (): Actions.RequestLocationsAction => ({
    type: ActionType.REQUEST_LOCATIONS,
})

export const fetchLocations = (): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestLocations())

        REST.get(LOCATIONS_URL).then((response) => {
            dispatch(setLocations(response.data))
        })
    }
}

export function refreshLocations(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        dispatch(requestRefreshLocationsAction())

        REST.get(LOCATIONS_URL + '/refresh')
            .then(() => {
                dispatch(setRefreshLocations(true))
            })
            .catch(() => {
                dispatch(setRefreshLocations(false))
            })
    }
}

const setRefreshLocations = (isRefreshed: boolean): Actions.SetRefreshLocationsAction => ({
    type: ActionType.SET_REFRESH_LOCATIONS,
    isRefreshed: isRefreshed,
})

function requestRefreshLocationsAction(): Actions.RefreshLocationsAction {
    return {
        type: ActionType.REFRESH_LOCATIONS,
    }
}
