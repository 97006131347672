import {GuidType} from '../../../values/generic-type-defintions'

export interface Location {
    location: GuidType
    code: string
    description: string
    type: string
    environment: string
}

export interface LocationsReduxState {
    isFetching: boolean
    locations: Location[]
    isRefreshed: boolean | null
}

export const DEFAULT_LOCATIONS_STATE: LocationsReduxState = {
    isFetching: true,
    locations: [],
    isRefreshed: null,
}
